export interface Machine {
  dateTime: number;
  engineTemp: number;
  gearPosition: number | string;
  gearVoltage: number;
  isConnected: boolean;
  millis: number;
  oilPressure: number;
  oilTemp: number;
  rpm: number;
  throttlePosition: number;
  latitude?: number;
  altitude?: number;
  longitude?: number;
  gpsSpeed3D?: number;
  gpsSpeed2D?: number;
  verticalAccel?: number;
  lateralAccel?: number;
  longitudinalAccel?: number;
  speed?: number;
  rollRate?: number;
  pitchRate?: number;
  yawRate?: number;
}

export type MachineKeys = keyof Machine;

export const RPM_MAX: number = 10000;
export const RPM_MIN: number = 0;

export const THROTTLE_POSITION_MAX: number = 100;
export const THROTTLE_POSITION_MIN: number = 0;

export const GEAR_VOLTAGE_MAX: number = 8;
export const GEAR_VOLTAGE_MIN: number = 0;

export const OIL_PRESSURE_MAX: number = 2500;
export const OIL_PRESSURE_MIN: number = 0;

export const OIL_TEMP_MAX: number = 200;
export const OIL_TEMP_MIN: number = 0;

export const ENGINE_TEMP_MAX: number = 170;
export const ENGINE_TEMP_MIN: number = 0;
