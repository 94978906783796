import { Button, Stack, TextField } from "@mui/material";
import { Controller,  useForm } from "react-hook-form";
import { writeMessage} from "../../core/db";

type MessageFormProps = {
  message: string
}
  
const MessageForm = () => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<MessageFormProps>();

  const onSubmitMessage = (data: MessageFormProps) => {
    console.log(data.message); // カンマ区切りで表示される
    writeMessage(data.message)
  };


  return (
        <>
        <label style={{fontSize: 30}}>Message</label>
          <Stack component="form" noValidate onSubmit={handleSubmit(onSubmitMessage)} direction="row"
          spacing={2} sx={{ m: 2, width: '25rm'}}
          >
          <Controller
              render={({ field }) => (
                <TextField
                inputProps={{
                  style: {
                    fontSize: 40
                  },
              }}
              error={Boolean(errors["message"])}
              helperText={errors["message"] && "8文字以内で入力してください"}
              {...field}
                />
              )}
              name="message"
              defaultValue=""
              control={control}
            />
          <Button variant="contained" type="submit">
            送信
          </Button>
          </Stack>
        </>
  );
};

  export default MessageForm