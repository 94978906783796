import { Button, Stack, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { writeMemo } from "../../core/db";
import { Memo } from "../../core/models/memo";

interface Props {
  date: number;
}

type MemoFormProps = {
  text: string;
};

const MemoForm = ({ date }: Props) => {
  const {
    control,
    handleSubmit,
    // formState: { errors },
  } = useForm<MemoFormProps>();

  const onSubmitMemo = (data: MemoFormProps) => {
    const memo: Memo = { text: data.text, date: date };
    writeMemo(memo);
  };

  return (
    <div style={{ marginTop: 30 }}>
      <Stack
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmitMemo)}
        direction="row"
        spacing={2}
        sx={{ width: "25rm" }}
      >
        <Controller
          render={({ field }) => (
            <TextField fullWidth label="memo" {...field} />
          )}
          name="text"
          defaultValue=""
          control={control}
        />
        <Button variant="contained" type="submit">
          保存
        </Button>
      </Stack>
    </div>
  );
};

export default MemoForm;
