import React from "react";
import { readLap } from "../../core/db";
import { Communication } from "../../core/models/communication";

interface State {
    communication: Communication
}

interface Props {}

class CurrentLap extends React.Component<Props, State> {
    
    constructor(props: Props | Readonly<Props>) {
      super(props);
      this.state = {
       communication :{
        lap:0,
        message: ""
       },
      };
    }
    
    async componentDidMount() {
      const comm = await (await readLap())
      this.setState({
        communication: {lap: comm.lap, message :comm.message}
      });
      setInterval(async ()=>{
        const comm = await (await readLap())
        this.setState({
          communication: {lap: comm.lap, message :comm.message}
        });
      }, 2000) 
    }

    render () {
      return (
        <div style={{fontSize: 40, padding: 30}}>
            <label >現在のLap:  {this.state.communication.lap.toString()}</label>
            <br></br>
            <label >現在のMessage:  {this.state.communication.message}</label>
        </div>
      )
    }
  }

  export default CurrentLap