import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import DashPage from "./view/pages/dash-page";
import MachineDataPage from "./view/pages/machine-data-page";
import DataManagementPage from "./view/pages/data-management";

import MessagePage from "./view/pages/message-page";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<MessagePage/>} />
      <Route path="/dash" element={<DashPage/>} />
      <Route path="/machine-data" element={<MachineDataPage/>} />
      <Route path="/data-management" element={<DataManagementPage/>} />
      </Routes>
    </Router>
  );
};

export default App;
