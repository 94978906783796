import { Button, Grid } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import {
  deleteLogsByDate,
  readDates,
  readLogsByDate,
  readMemos,
} from "../../core/db";
import DatesForm from "../components/dates-form";
import GenericTemplate from "../templates/generic-template";
import fileDownload from "js-file-download";
import { Memo } from "../../core/models/memo";

const DataManagementPage = () => {
  const [date, setDate] = useState(0);
  const [dates, setDates] = useState<number[]>([]);
  const [memos, setMemos] = useState<Memo[]>([]);
  useEffect(() => {
    const data = async () => {
      const dates = await readDates();
      setDates(dates);
      const memos = await readMemos();
      setMemos(memos);
    };
    data();
  });
  const onClickDeleteButton = () => {
    let check = window.confirm(
      `本当に${format(date, "yyyy/MM/dd HH:mm:ss SSS")}削除しますか？`
    );
    if (check) {
      deleteLogsByDate(date);
    }
  };

  const onClickDownloadButton = async () => {
    const newMachines = await readLogsByDate(date);
    let csv: string =
      "DateTime,Rpm,ThrottlePosition,EngineTemp,OilTemp,OilPressure,GearPosition,GearVoltage,millis,isConnected\n";
    for (const m of newMachines) {
      const row = `${format(m.dateTime, "yyyy/MM/dd HH:mm:ss SSS")},${m.rpm},${
        m.throttlePosition
      },${m.engineTemp},${m.oilTemp},${m.oilPressure},${m.gearPosition},${
        m.gearVoltage
      },${m.millis},${m.isConnected}\n`;
      csv = csv + row;
    }
    const blob = new Blob([csv], { type: "text/csv" });
    const result = memos.find((memo) => memo.date === date);
    fileDownload(
      blob,
      `${format(date, "yyyy/MM/dd HH:mm:ss SSS")}_${result}.csv`
    );
  };

  return (
    <GenericTemplate title="Data Management">
      {dates.length > 0 && (
        <DatesForm
          dates={dates}
          memos={memos}
          onSelectDate={(date: number) => setDate(date)}
        />
      )}
      <div style={{ paddingTop: 30 }}>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              type="submit"
              onClick={() => onClickDeleteButton()}
              disabled={date === 0}
              color="error"
            >
              データ削除
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              type="submit"
              onClick={() => onClickDownloadButton()}
              disabled={date === 0}
            >
              データダウンロード
            </Button>
          </Grid>
        </Grid>
      </div>
    </GenericTemplate>
  );
};
export default DataManagementPage;
