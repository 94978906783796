import { Alert, Divider, Grid, Snackbar } from "@mui/material";
import LapForm from "../components/lap-form";

import GenericTemplate from "../templates/generic-template";

import CurrentLap from "../components/current-lap";
import MessageForm from "../components/message-form";
import PassageButton from "../components/passage-button";
import LapTable from "../components/lap-table";
import { useEffect, useState } from "react";
import {
  writePassage,
  writeLap,
  readLap,
  readPassages,
  readDates,
  deletePassage,
} from "../../core/db";

const MessagePage = () => {
  const [lap, setLap] = useState<number>(0);
  const [openPassageSnackbar, setOpenPassageSnackbar] =
    useState<boolean>(false);
  const [openDeletePassageSnackbar, setOpenDeletePassageSnackbar] =
    useState<boolean>(false);
  const [passages, setPassages] = useState<number[]>([]);
  // const [dates, setDates] = useState<number[]>([]);

  useEffect(() => {
    (async () => {
      const dates = await readDates();
      const lastDate = dates.slice(-1)[0];
      const passages = await readPassages(lastDate, new Date().getTime());
      console.log(lastDate);
      console.log(passages);
      setPassages(passages);
    })();
    setInterval(async () => {
      const dates = await readDates();
      const lastDate = dates.slice(-1)[0];
      const passages = await readPassages(lastDate, new Date().getTime());
      setPassages(passages);
    }, 2000);
  }, []);

  const onClickPassageButton = () => {
    const date = new Date();
    writePassage(date.getTime());
    writeLap(lap + 1);
    setOpenPassageSnackbar(true);
  };

  const onClickDeletePassageButton = async (passage: number) => {
    await deletePassage(passage);
    setOpenDeletePassageSnackbar(true);
  };

  useEffect(() => {
    (async () => {
      setLap((await readLap()).lap);
    })();
    setInterval(async () => setLap((await readLap()).lap), 2000);
  }, []);

  return (
    <GenericTemplate title="Message">
      <Grid container direction="column">
        <Snackbar
          open={openPassageSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={3000}
          onClose={() => setOpenPassageSnackbar(false)}
          sx={{ width: "90%" }}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Lapを記録しました
          </Alert>
        </Snackbar>
        <Snackbar
          open={openDeletePassageSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={3000}
          onClose={() => setOpenDeletePassageSnackbar(false)}
          sx={{ width: "90%" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Lapを削除しました
          </Alert>
        </Snackbar>
        <CurrentLap />
        <Divider />
        <LapForm />
        <MessageForm />
        <PassageButton onClickPassageButton={onClickPassageButton} />
        <LapTable
          passages={passages}
          onClickDeletePassage={onClickDeletePassageButton}
        />
      </Grid>
    </GenericTemplate>
  );
};
export default MessagePage;
