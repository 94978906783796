import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { format } from "date-fns";

interface Props {
  passages: number[];
  onClickDeletePassage: (passage: number) => void;
}

const LapTable = ({ passages, onClickDeletePassage }: Props) => {
  return (
    <div style={{ paddingTop: 30 }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Lap</TableCell>
              <TableCell align="right">Start</TableCell>
              <TableCell align="right">End</TableCell>
              <TableCell align="right">Lap Time (s)</TableCell>
              <TableCell align="center">削除</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {passages
              .map((passage, index) => {
                return (
                  <TableRow
                    key={passage}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="right">
                      {format(new Date(passage), "yy-MM-dd HH:mm:ss SSS")}
                    </TableCell>
                    <TableCell align="right">
                      {index === passages.length - 1
                        ? ""
                        : format(new Date(passages[index + 1]), "HH:mm:ss SSS")}
                    </TableCell>
                    <TableCell align="right">
                      {index === passages.length - 1
                        ? ""
                        : (passages[index + 1] - passage) / 1000}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        sx={{ height: "35px", width: "30px", ml: "20px" }}
                        color="error"
                        onClick={(_) => onClickDeletePassage(passage)}
                      >
                        ✕
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
              .reverse()}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default LapTable;
