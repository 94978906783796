import { Button, Grid } from "@mui/material";
import { GraphType } from "../../core/models/graph";

interface Props {
    currentGraphType: GraphType
    onChangeGraphType: (graphType: GraphType) => void
  }

const GraphTypeButtons = ({currentGraphType, onChangeGraphType}: Props) => {
  return (
    <div style={{display: "flex", paddingTop: 30 }}>
        <Grid container spacing={2}>
        <Grid item>
        <Button  variant="contained" onClick={() => onChangeGraphType('all')} disabled={currentGraphType === 'all'}>
            全体を表示
        </Button>
        </Grid>
        <Grid item>
        <Button  variant="contained" onClick={() => onChangeGraphType('lap')} disabled={currentGraphType === 'lap'}>
            Lapごとに比較
        </Button>
        </Grid>
        </Grid>
    </div>
  );
};

export default GraphTypeButtons;
