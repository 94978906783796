import { useEffect, useState } from "react";
import { Machine } from "../../core/models/machine";
import { MapContainer, Polyline, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";

interface Props {
  machines: Machine[];
  passages: number[];
  laps: number[];
  colors: string[];
  date: number;
}

interface Center {
  lat: number;
  lng: number;
}

interface CenterProps {
  center: Center;
}

const ChangeMapCenter = ({ center }: CenterProps) => {
  const map = useMap();
  map.panTo(center);
  return null;
};

const containerStyle = {
  height: "640px",
  width: "100%",
};

const GpsMap = ({ machines, passages, laps, colors, date }: Props) => {
  const [firstLoaded, setFirstLoaded] = useState<boolean>(false);
  const [renderMachines, setRenderMachines] = useState<Machine[]>([]);

  const defaultCenter: Center = {
    lat: 35.02616714173866,
    lng: 135.7820354469326,
  };
  const [center, setCenter] = useState<Center>(defaultCenter);

  useEffect(() => {
    var ms: Machine[] = [];
    machines.forEach(function (e) {
      if (
        e.latitude !== undefined &&
        e.longitude !== undefined &&
        e.latitude !== 0 &&
        e.longitude !== 0
      ) {
        ms.push(e);
      }
    });
    setRenderMachines(ms);
    if (!firstLoaded) {
      const center = getCenter(ms);
      setCenter(center);
      if (center !== defaultCenter) {
        setFirstLoaded(true);
      }
    }
  }, [machines, laps]);

  useEffect(() => {
    setFirstLoaded(false);
  }, [date]);

  const getCenter = (machines: Machine[]) => {
    if (machines.length === 0) {
      return defaultCenter;
    }
    var sumLat = 0;
    var sumLng = 0;
    machines.forEach(function (e) {
      if (e.latitude !== undefined && e.longitude !== undefined) {
        sumLat += e.latitude;
        sumLng += e.longitude;
      }
    });
    const center: Center = {
      lat: sumLat / machines.length,
      lng: sumLng / machines.length,
    };
    return center;
  };

  return (
    <MapContainer
      center={center}
      zoom={18}
      scrollWheelZoom={true}
      style={containerStyle}
    >
      <ChangeMapCenter center={center} />
      <TileLayer
        url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
        maxZoom={20}
        subdomains={["mt1", "mt2", "mt3"]}
      />
      {laps.map((lap) => {
        const start = passages[lap];
        const end = passages[lap + 1];
        return (
          <Polyline
            positions={renderMachines
              .filter(
                (renderMachines) =>
                  start <= renderMachines.dateTime &&
                  renderMachines.dateTime <= end
              )
              .map((machine) => [
                machine.latitude as number,
                machine.longitude as number,
              ])}
            key={lap}
            color={colors[lap]}
          />
        );
      })}
    </MapContainer>
  );
};

export default GpsMap;
