import { Button } from "@mui/material";

interface Props {
  onClickPassageButton: () => void;
}

const PassageButton = ({ onClickPassageButton }: Props) => {
  return (
    <Button
      variant="contained"
      style={{ height: 200 }}
      onClick={onClickPassageButton}
    >
      通過
    </Button>
  );
};

export default PassageButton;
