import { Data } from "plotly.js";
import { GraphType } from "../../core/models/graph";
import {
  Machine,
  RPM_MIN,
  RPM_MAX,
  THROTTLE_POSITION_MIN,
  THROTTLE_POSITION_MAX,
  ENGINE_TEMP_MIN,
  ENGINE_TEMP_MAX,
  OIL_TEMP_MIN,
  OIL_TEMP_MAX,
  OIL_PRESSURE_MIN,
  OIL_PRESSURE_MAX,
  GEAR_VOLTAGE_MIN,
  GEAR_VOLTAGE_MAX,
} from "../../core/models/machine";

import Plot from "react-plotly.js";

interface Props {
  machines: Machine[];
  shouldUirevision: boolean;
  currentGraphType: GraphType;
  colors: string[];
  passages: number[];
  laps: number[];
}

const getDomain = (i: number, N: number = 6, spacing: number = 0.4) => {
  //https://community.plotly.com/t/add-spacing-between-subplots-in-plotly-js/2970 参照
  const spacingPerFace = spacing / ((N - 1) * 2);
  const expectedDomain = (1 - spacing) / N;

  const previousDomainLocked = expectedDomain * i;
  const previousSpaceLocked = spacingPerFace * (i * 2 - 1);
  const endValue =
    previousDomainLocked +
    previousSpaceLocked +
    expectedDomain +
    spacingPerFace;

  return [
    i === 0 ? 0 : previousDomainLocked + previousSpaceLocked + spacingPerFace,
    i === N - 1 ? 1 : endValue,
  ];
};

const Graph = ({
  machines,
  shouldUirevision,
  currentGraphType,
  colors,
  passages,
  laps,
}: Props) => {
  // const lapDurations = passages.slice(1).map(
  //     (passage, index) => {
  //         return passage - passages[index]
  //     }
  // )

  // const maxLapDurations = Math.max(...lapDurations)

  // const xData = machines.map((machine) => {
  //     const date = new Date(machine.dateTime)
  //     return date
  // })

  const createData = () => {
    if (currentGraphType === "lap") {
      var data: Data[] = [];
      for (const lap of laps) {
        const start = machines.reduce((a, b) =>
          a.dateTime > b.dateTime ? b : a
        ).dateTime;
        const startAndPassages = [start, ...passages];
        const xData = machines
          .filter(
            (machine) =>
              startAndPassages[lap] <= machine.dateTime &&
              startAndPassages[lap + 1] >= machine.dateTime
          )
          .map((machine) => {
            const timeDelta = machine.dateTime - startAndPassages[lap];
            return timeDelta / 1000;
          });
        const machineData = machines.filter(
          (machine) =>
            startAndPassages[lap] <= machine.dateTime &&
            startAndPassages[lap + 1] >= machine.dateTime
        );
        data.push(
          ...([
            {
              x: xData,
              y: machineData.map((machine) => machine["rpm"] as number),
              xaxis: `x1`,
              yaxis: "y6",
              type: "scatter",
              mode: "lines+markers",
              marker: { color: colors[lap], size: 1 },
            },
            {
              x: xData,
              y: machineData.map((machine) => machine["engineTemp"] as number),
              xaxis: `x1`,
              yaxis: "y5",
              type: "scatter",
              mode: "lines+markers",
              marker: { color: colors[lap], size: 1 },
            },
            {
              x: xData,
              y: machineData.map((machine) => machine["oilTemp"] as number),
              xaxis: `x1`,
              yaxis: "y4",
              type: "scatter",
              mode: "lines+markers",
              marker: { color: colors[lap], size: 1 },
            },
            {
              x: xData,
              y: machineData.map((machine) => machine["oilPressure"] as number),
              xaxis: `x1`,
              yaxis: "y3",
              type: "scatter",
              mode: "lines+markers",
              marker: { color: colors[lap], size: 1 },
            },
            {
              x: xData,
              y: machineData.map(
                (machine) => machine[`throttlePosition`] as number
              ),
              xaxis: `x1`,
              yaxis: "y2",
              type: "scatter",
              mode: "lines+markers",
              marker: { color: colors[lap], size: 1 },
            },
            {
              x: xData,
              y: machineData.map((machine) => machine["gearVoltage"] as number),
              xaxis: `x1`,
              yaxis: "y1",
              type: "scatter",
              mode: "lines+markers",
              marker: { color: colors[lap], size: 1 },
            },
          ] as Data[])
        );
      }
      return data;
    } else {
      const xData = machines.map((machine) => {
        const date = new Date(machine.dateTime);
        return date;
      });
      return [
        {
          x: xData,
          y: machines.map((machine) => machine["rpm"] as number),
          xaxis: `x1`,
          yaxis: "y6",
          type: "scatter",
          mode: "lines+markers",
          marker: { color: "red", size: 1 },
        },
        {
          x: xData,
          y: machines.map((machine) => machine["engineTemp"] as number),
          xaxis: "x1",
          yaxis: "y5",
          type: "scatter",
          mode: "lines+markers",
          marker: { color: "black", size: 1 },
        },
        {
          x: xData,
          y: [machines.map((machine) => machine["oilTemp"] as number)],
          xaxis: "x1",
          yaxis: "y4",
          type: "scatter",
          mode: "lines+markers",
          marker: { color: "green", size: 1 },
        },
        {
          x: xData,
          y: [machines.map((machine) => machine["oilPressure"] as number)],
          xaxis: "x1",
          yaxis: "y3",
          type: "scatter",
          mode: "lines+markers",
          marker: { color: "blue", size: 1 },
        },
        {
          x: xData,
          y: machines.map((machine) => machine[`throttlePosition`] as number),
          xaxis: "x1",
          yaxis: "y2",
          type: "scatter",
          mode: "lines+markers",
          marker: { color: "gray", size: 1 },
        },
        {
          x: xData,
          y: machines.map((machine) => machine["gearVoltage"] as number),
          xaxis: "x1",
          yaxis: "y1",
          type: "scatter",
          mode: "lines+markers",
          marker: { color: "purple", size: 1 },
        },
      ] as Data[];
    }
  };

  return (
    <div style={{ fontSize: 40, paddingTop: 30 }}>
      <Plot
        style={{ width: "100%", height: 800 }}
        useResizeHandler={true}
        data={createData()}
        layout={{
          autosize: false,
          uirevision: shouldUirevision ? "true" : "false",
          showlegend: false,
          grid: { rows: 6, columns: 1, pattern: "coupled" },
          xaxis: { showspikes: true, spikemode: "across", spikecolor: "grey" },
          yaxis6: {
            autorange: false,
            range: [RPM_MIN, RPM_MAX],
            domain: getDomain(5),
            title: {
              text: "RPM (/min)",
              font: { size: 15, color: "red" },
            },
            exponentformat: "none",
          },
          yaxis5: {
            autorange: false,
            range: [ENGINE_TEMP_MIN, ENGINE_TEMP_MAX],
            domain: getDomain(4),
            title: {
              text: "Engine<br>Temp (℃)",
              font: { size: 15, color: "black" },
            },
          },
          yaxis4: {
            autorange: false,
            range: [OIL_TEMP_MIN, OIL_TEMP_MAX],
            domain: getDomain(3),
            title: {
              text: "Oil<br>Temp (℃)",
              font: { size: 15, color: "green" },
            },
          },
          yaxis3: {
            autorange: false,
            range: [OIL_PRESSURE_MIN, OIL_PRESSURE_MAX],
            domain: getDomain(2),
            title: {
              text: "Oil<br>Pressure (kPa)",
              font: { size: 15, color: "blue" },
            },
          },
          yaxis2: {
            autorange: false,
            range: [THROTTLE_POSITION_MIN, THROTTLE_POSITION_MAX],
            domain: getDomain(1),
            title: {
              text: "Throttle<br>Position (%)",
              font: { size: 15, color: "gray" },
            },
          },
          yaxis: {
            autorange: false,
            range: [GEAR_VOLTAGE_MIN, GEAR_VOLTAGE_MAX],
            domain: getDomain(0),
            title: {
              text: "Gear<br>Voltage (V)",
              font: { size: 15, color: "purple" },
            },
          },
        }}
      />
    </div>
  );
};

export default Graph;
