import {
  getDatabase,
  ref,
  update,
  get,
  startAt,
  orderByKey,
  query,
  remove,
  onValue,
  push,
  orderByValue,
  endAt,
  limitToLast,
} from "firebase/database";
import firebaseApp from "./firebase";
import { Communication } from "./models/communication";
import { Machine } from "./models/machine";
import { Memo } from "./models/memo";

export const writeLap = (lap: number) => {
  const db = getDatabase(firebaseApp);
  return update(ref(db, "communication"), {
    lap: lap,
  });
};

export const writeMessage = (message: string) => {
  const db = getDatabase(firebaseApp);
  return update(ref(db, "communication"), {
    message: message,
  });
};

export const writeMemo = (memo: Memo) => {
  const db = getDatabase(firebaseApp);
  return update(ref(db, `memos/${memo.date}`), memo);
};

export const readMemo = async (date: number) => {
  const db = getDatabase(firebaseApp);
  const snapshot = await get(ref(db, "memos/" + date.toString()));
  return snapshot.val() as Memo;
};

export const readMemos = async () => {
  const db = getDatabase(firebaseApp);
  const snapshot = await get(ref(db, "memos"));
  var memos: Memo[] = [];
  snapshot.forEach((childSnapshot) => {
    memos.push(childSnapshot.val() as Memo);
  });
  return memos;
};

export const readLap = async () => {
  const db = getDatabase(firebaseApp);
  const snapshot = await get(ref(db, "communication"));
  return snapshot.val() as Communication;
};

export const readLogsByDate = async (date: number, dateFrom?: number) => {
  const logs: Machine[] = [];
  const db = getDatabase(firebaseApp);
  const logsRef = ref(db, `machineLogs/${date}`);
  var snapshot;
  if (dateFrom) {
    const logsDateFromRef = query(
      logsRef,
      orderByKey(),
      startAt(dateFrom.toString())
    );
    snapshot = await get(logsDateFromRef);
  } else {
    snapshot = await get(logsRef);
  }
  snapshot.forEach((childSnapshot) => {
    logs.push(childSnapshot.val() as Machine);
  });
  return logs;
};
export const deleteLogsByDate = async (date: number) => {
  const db = getDatabase(firebaseApp);
  const logsRef = ref(db, `machineLogs/${date}`);
  remove(logsRef);
  const memoRef = ref(db, `memos/${date}`);
  remove(memoRef);
  const datesRef = ref(db, "dates");
  onValue(
    datesRef,
    (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        const childData = childSnapshot.val();
        console.log(childKey);
        if (childData === date.toString()) {
          remove(ref(db, `dates/${childKey}`));
        }
      });
    },
    {
      onlyOnce: true,
    }
  );
};

export const readDates = async () => {
  const db = getDatabase(firebaseApp);
  const snapshot = await get(ref(db, "dates"));
  const dates: number[] = [];
  snapshot.forEach((childSnapshot) => {
    dates.push(Number(childSnapshot.val()));
  });
  return dates;
};

export const readPassages = async (
  startDateTime: number,
  endDateTime: number
) => {
  console.log(startDateTime);
  console.log(endDateTime);
  const db = getDatabase(firebaseApp);
  const passages: number[] = [];
  const snapshot = await get(
    query(
      ref(db, "passages"),
      orderByValue(),
      startAt(startDateTime),
      endAt(endDateTime)
    )
  );
  snapshot.forEach((childSnapshot) => {
    passages.push(Number(childSnapshot.val()));
  });
  return passages;
};

export const readPassagesByMachines = async (machines: Machine[]) => {
  if (machines.length >= 2) {
    const start = machines.reduce((a, b) =>
      a.dateTime > b.dateTime ? b : a
    ).dateTime;
    const end = machines.reduce((a, b) =>
      a.dateTime > b.dateTime ? a : b
    ).dateTime;
    return readPassages(start, end);
  }
  return [];
};

export const readLastNPassages = async (n: number) => {
  const db = getDatabase(firebaseApp);
  const passages: [string, number][] = [];
  const snapshot = await get(
    query(ref(db, "passages"), orderByValue(), limitToLast(n))
  );
  snapshot.forEach((childSnapshot) => {
    passages.push([String(childSnapshot.key), Number(childSnapshot.val())]);
  });
  return passages;
};

export const deletePassageByKey = async (key: string) => {
  const db = getDatabase(firebaseApp);
  remove(ref(db, `passages/${key}`));
};

export const deletePassage = async (passage: number) => {
  const db = getDatabase(firebaseApp);
  const snapshot = await get(query(ref(db, "passages"), orderByValue()));
  snapshot.forEach((childSnapshot) => {
    if (childSnapshot.val() === passage) {
      remove(ref(db, `passages/${childSnapshot.key}`));
      return;
    }
  });
};

export const writePassage = async (time: number) => {
  const db = getDatabase(firebaseApp);
  push(ref(db, "passages"), time);
};
