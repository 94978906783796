// １．FormすべてのPropsを宣言

import { TextField, Stack, Button } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { writeLap } from "../../core/db";

type LapFormProps = {
  lap: string
};
  
const LapForm = () => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<LapFormProps>();

  const onSubmitLap = (data: LapFormProps) => {
    console.log(Number(data.lap).toLocaleString()); // カンマ区切りで表示される
    writeLap(Number(data.lap))
  };

  return (
    <>
    <label style={{fontSize: 30, marginTop: 20}}>Lap</label>
      <Stack component="form" noValidate onSubmit={handleSubmit(onSubmitLap)} direction="row"
      spacing={2} sx={{ m: 2, width: '25rm' }}
      >
      <Controller
          render={({ field }) => (
            <TextField
            inputProps={{
              style: {
                fontSize: 40
              },
          }}
              error={Boolean(errors["lap"])}
              helperText={errors["lap"] && "半角数字を入力してください"}
              {...field}
            />
          )}
          name="lap"
          defaultValue=""
          control={control}
          rules={{ pattern: /^[0-9]+$/ }}
        />
      <Button variant="contained" type="submit">
        送信
      </Button>
      </Stack>
    </>
  );
};

  export default LapForm