import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { format } from "date-fns";
import { Machine } from "../../core/models/machine";
import { useState } from "react";

interface Props {
  passages: number[];
  onChecked: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  onSelectAll: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  machines: Machine[];
  colors: string[];
  selectedLaps: number[];
}

const LapSelector = ({
  passages,
  onChecked,
  onSelectAll,
  machines,
  colors,
  selectedLaps,
}: Props) => {
  //console.log(passages)
  const [allSelectionCount, setAllSelectionCount] = useState<number>(0);
  if (machines.length >= 2) {
    const start = machines.reduce((a, b) =>
      a.dateTime > b.dateTime ? b : a
    ).dateTime;
    // const end = machines.reduce((a, b) =>
    //   a.dateTime > b.dateTime ? a : b
    // ).dateTime;
    return (
      <div>
        <FormGroup style={{ display: "flex", paddingBottom: 10 }}>
          <FormControlLabel
            key={"all"}
            control={
              <>
                <Checkbox
                  onChange={(event, checked) => {
                    setAllSelectionCount(allSelectionCount + 1);
                    onSelectAll(event, checked);
                  }}
                  style={{ height: 12, width: 40, color: "black" }}
                />
                <div
                  style={{
                    display: "flex",
                    width: 182,
                    justifyContent: "space-between",
                    paddingRight: 30,
                  }}
                >
                  <div>
                    {allSelectionCount % 2 === 0
                      ? "Select All"
                      : "Deselect All"}
                  </div>
                </div>
              </>
            }
            label={""}
          />
        </FormGroup>
        <FormGroup
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            paddingBottom: 10,
            height: ((passages.length + 1) / 4) * 24 + 24,
          }}
        >
          {[start, ...passages].map((passage, lap) => {
            var laptext = "";
            if (lap === 0) {
              laptext = "start";
            } else {
              laptext = `Lap ${lap}`;
            }
            return (
              <div style={{ display: "flex", justifyContent: "left" }}>
                <FormControlLabel
                  key={lap}
                  control={
                    <>
                      <Checkbox
                        value={lap}
                        onChange={onChecked}
                        style={{ height: 12, width: 40, color: colors[lap] }}
                        checked={selectedLaps.includes(lap)}
                      />
                      <div
                        style={{
                          display: "flex",
                          width: 182,
                          justifyContent: "space-between",
                          paddingRight: 30,
                        }}
                      >
                        <div>{laptext}</div>
                        <div>{`: ${format(
                          new Date(passage),
                          "HH:mm:ss SSS"
                        )}`}</div>
                      </div>
                    </>
                  }
                  label={""}
                />
              </div>
            );
          })}
        </FormGroup>
      </div>
    );
  } else {
    return <FormGroup />;
  }
};

export default LapSelector;
