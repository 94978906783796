// １．FormすべてのPropsを宣言
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { format } from "date-fns";
import { Memo } from "../../core/models/memo";

type State = {
  date: number;
};

interface Props {
  dates: number[];
  memos: Memo[];
  onSelectDate: (date: number) => void;
  defaultValue?: number;
}

const DateForm = ({ dates, memos, onSelectDate, defaultValue }: Props) => {
  const {
    control,
    // formState: { errors },
  } = useForm<State>({});

  return (
    <Controller
      name="date"
      control={control}
      render={({ field, fieldState }) => (
        <FormControl fullWidth>
          <InputLabel id="area-label">date</InputLabel>
          <Select
            label="date"
            {...field}
            defaultValue={defaultValue}
            onChange={(e) => onSelectDate(Number(e.target.value))}
          >
            {dates.map((d) => {
              if (d === undefined) return null;
              const date = new Date(d);
              const memo = memos.find((memo) => memo.date === d);
              if (memo) {
                return (
                  <MenuItem value={d} key={d}>
                    {format(date, "yyyy/MM/dd HH:mm:ss SSS") +
                      "   " +
                      memo.text}
                  </MenuItem>
                );
              }
              return (
                <MenuItem value={d} key={d}>
                  {format(date, "yyyy/MM/dd HH:mm:ss SSS")}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default DateForm;
