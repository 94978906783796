import { useEffect, useState } from "react";
import {
  deleteLogsByDate,
  readDates,
  readLogsByDate,
  readMemos,
  readPassagesByMachines,
} from "../../core/db";
import { Machine } from "../../core/models/machine";
import { getIsDuplicate } from "../../utils/list";
import DatesForm from "../components/dates-form";
import Graph from "../components/graph";
import GenericTemplate from "../templates/generic-template";
import { useValueRef } from "../use-intervals";
import MemoForm from "../components/memo-form";
import { Memo } from "../../core/models/memo";
import { format } from "date-fns";
import fileDownload from "js-file-download";
import { Alert, Button, Grid, Snackbar } from "@mui/material";
import LapSelector from "../components/lap-selector";
import GpsMap from "../components/gps-map";
import colormap from "colormap";
import { GraphType } from "../../core/models/graph";
import GraphTypeButtons from "../components/graph-type-buttons";

const MachineDataPage: React.FC = () => {
  const [machines, setMachines] = useState<Machine[]>([]);
  const [date, setDate] = useState(0);
  const [dates, setDates] = useState<number[]>([]);
  const [shouldUirevision, setShouldUirevision] = useState<boolean>(false);
  const refMachines = useValueRef(machines);
  const refDate = useValueRef(date);
  const [memos, setMemos] = useState<Memo[]>([]);
  const [selectedLaps, setSelectedLaps] = useState<number[]>([]);
  const [passages, setPassages] = useState<number[]>([]);
  const refPassages = useValueRef(passages);
  const [colors, setColors] = useState<string[]>(
    colormap({
      colormap: "jet",
      nshades: 6,
      format: "hex",
      alpha: 1,
    })
  );
  const [currentGraphType, setCurrentGraphType] = useState<GraphType>("all");
  const [openAlertGraphTypeSnackbar, setOpenAlertGraphTypeSnackbar] =
    useState<boolean>(false);

  const getEvery = async () => {
    const dates = await readDates();
    const memos = await readMemos();
    setDates(dates);
    setMemos(memos);
    const lastDate = dates.slice(-1)[0];
    if (refDate.current === 0) {
      setPassages([]);
      setMachines([]);
      setDate(lastDate);
    }
    if (refMachines.current.length === 0) {
      const newMachines = await readLogsByDate(refDate.current);
      setMachines(newMachines);
      setPassages(await readPassagesByMachines(newMachines));

      setShouldUirevision(false);
    } else {
      setShouldUirevision(true);
      const lastDateTime = refMachines.current.slice(-1)[0].dateTime;
      const newMachines = await readLogsByDate(
        refDate.current,
        lastDateTime + 10
      );
      const newDateList = newMachines.map((machine) => machine.dateTime);
      const oldDateList = refMachines.current.map(
        (machine) => machine.dateTime
      );
      if (!getIsDuplicate(newDateList, oldDateList)) {
        setMachines([...refMachines.current, ...newMachines]);
        setPassages([
          ...refPassages.current,
          ...(await readPassagesByMachines(newMachines)),
        ]);
      }
    }
  };

  useEffect(() => {
    setColors(
      colormap({
        colormap: "jet",
        nshades: passages.length < 5 ? 6 : passages.length + 1,
        format: "hex",
        alpha: 1,
      })
    );
  }, [machines, selectedLaps]);

  const onSelectDate = async (date: number) => {
    // TODO
    console.log(date);
    setDate(date);
    setMachines([]);
    setPassages([]);
  };

  const selectLap = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const lap = Number(event.target.value);
    if (checked && !selectedLaps.includes(lap)) {
      setSelectedLaps([...selectedLaps, lap]);
    } else {
      setSelectedLaps(selectedLaps.filter((lap_) => lap_ !== lap));
    }
  };

  const selectAllLap = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      setSelectedLaps([...Array(passages.length + 1)].map((_, i) => i));
    } else {
      setSelectedLaps([]);
    }
  };

  useEffect(() => {
    getEvery();
    setInterval(async () => await getEvery(), 5000);
  }, []);

  useEffect(() => {
    if (selectedLaps.length <= 1 && currentGraphType === "lap") {
      setOpenAlertGraphTypeSnackbar(true);
    }
  }, [currentGraphType, selectedLaps.length]);

  const onClickDeleteButton = () => {
    let check = window.confirm(
      `本当に${format(date, "yyyy/MM/dd HH:mm:ss SSS")}のデータを削除しますか？`
    );
    if (check) {
      deleteLogsByDate(date);
    }
  };
  const onClickDownloadButton = async () => {
    const newMachines = await readLogsByDate(date);
    let csv: string =
      "DateTime,Rpm,ThrottlePosition,EngineTemp,OilTemp,OilPressure,GearPosition,GearVoltage,millis,isConnected\n";
    for (const m of newMachines) {
      const row = `${format(m.dateTime, "yyyy/MM/dd HH:mm:ss SSS")},${m.rpm},${
        m.throttlePosition
      },${m.engineTemp},${m.oilTemp},${m.oilPressure},${m.gearPosition},${
        m.gearVoltage
      },${m.millis},${m.isConnected}\n`;
      csv = csv + row;
    }
    const blob = new Blob([csv], { type: "text/csv" });
    const result = memos.find((memo) => memo.date === date);
    if (result) {
      fileDownload(
        blob,
        `${format(date, "yyyy/MM/dd HH:mm:ss SSS")}_${result?.text}.csv`
      );
    } else {
      fileDownload(blob, `${format(date, "yyyy/MM/dd HH:mm:ss SSS")}.csv`);
    }
  };

  return (
    <GenericTemplate title="Machine Data">
      <Snackbar
        open={openAlertGraphTypeSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={() => setOpenAlertGraphTypeSnackbar(false)}
        sx={{ width: "90%" }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Lapを2つ以上選択してください
        </Alert>
      </Snackbar>
      {dates.length > 0 && (
        <DatesForm
          memos={memos}
          dates={dates}
          onSelectDate={onSelectDate}
          defaultValue={dates.slice(-1)[0]}
        />
      )}
      <MemoForm date={date} />
      <div style={{ paddingTop: 30 }}>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              type="submit"
              onClick={() => onClickDeleteButton()}
              disabled={date === 0}
              color="error"
            >
              データ削除
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              type="submit"
              onClick={() => onClickDownloadButton()}
              disabled={date === 0}
            >
              データダウンロード
            </Button>
          </Grid>
        </Grid>
      </div>
      <div style={{ paddingTop: 30 }}>
        <LapSelector
          passages={passages}
          onChecked={selectLap}
          onSelectAll={selectAllLap}
          machines={machines}
          colors={colors}
          selectedLaps={selectedLaps}
        />
        <GpsMap
          machines={machines}
          passages={passages}
          laps={selectedLaps}
          colors={colors}
          date={date}
        />
      </div>
      <GraphTypeButtons
        currentGraphType={currentGraphType}
        onChangeGraphType={(graphType) => setCurrentGraphType(graphType)}
      />
      <Graph
        machines={machines}
        shouldUirevision={shouldUirevision}
        currentGraphType={currentGraphType}
        colors={colors}
        passages={passages}
        laps={selectedLaps}
      />
    </GenericTemplate>
  );
};
export default MachineDataPage;
